.chat-app {
  display: flex;
  height: 100vh;
}

.conversation-list {
  border-right: 1px solid #ccc;
  overflow-y: auto;
}

.conversation-list ul {
  list-style-type: none;
  padding: 0;
}

.active {
  background-color: #d0d2d4;
  border-bottom: 2px solid #303d50;
}
.conversation-list li {
  padding: 10px;
  /* border-bottom: 1px solid #eee; */
}

/* .conversation-list li:hover {
  background-color: #f0f0f0;
} */

/* .chat-window {
  flex: 1;
  display: flex;
  flex-direction: column;
} */

.messages {
  flex: 1;
  overflow-y: auto;
}

.message-meta {
  font-size: 0.7em;
  color: #666;
  font-weight: bolder;
}

.message-text {
  font-size: 0.8em;
}
#conv {
  padding-left: 0em;
  font-weight: 502;
  font-size: 0.9em;
}
#btn-accept {
  font-size: 0.7em;
  scale: 0.8;
}
#queueName {
  font-size: 0.8em;
}
#wrapupDiv {
  scale: 0.7;
}
#wrapUpSelect {
  font-size: 0.8em;
}
#chatStatus {
  font-weight: 600;
}
/* #messageInput{
  margin-left:-6px;
  width: 63%;
} */
#sendInput {
  margin-left: -4em;
}

/* loader */
/* HTML: <div class="loader"></div> */
.loader {
  width: 40px;
  height: 30px;
  --c: no-repeat linear-gradient(#f7f7f7 0 0);
  background: var(--c) 0 100%/8px 30px, var(--c) 50% 100%/8px 20px,
    var(--c) 100% 100%/8px 10px;
  position: relative;
  clip-path: inset(-100% 0);
}
.loader:before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #f0f0f0;
  left: -16px;
  top: 0;
  animation: l5-1 2s linear infinite,
    l5-2 0.5s cubic-bezier(0, 200, 0.8, 200) infinite;
}
@keyframes l5-1 {
  0% {
    left: -16px;
    transform: translateY(-8px);
  }
  100% {
    left: calc(100% + 8px);
    transform: translateY(22px);
  }
}
@keyframes l5-2 {
  100% {
    top: -0.1px;
  }
}
