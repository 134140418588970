.customer-details-container {
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  padding: 16px;
  max-width: 700px; /* Adjust width as needed */
  margin: 10px auto;
  width:98%;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.customer-info {
  display: flex;
  align-items: center;
  margin-left: -1em;
    scale: 0.8;
}

.customer-name {
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.status-indicator {
  width: 10px;
  height: 10px;
  background-color: #ccc;
  border-radius: 50%;
  margin-right: 8px;
}

.status-indicator.active {
  background-color: #0078d4; /* Blue color for active status */
}

.timer {
  font-size: 16px;
  font-weight: bold;
  color: #555;
  display: flex;
  align-items: center;
  gap: 8px;
}

.action-buttons {
  display: flex;
  gap: 8px;
}

.action-buttons .btn {
  padding: 8px 12px;
  border: none;
  border-radius: 24px; /* Rounded buttons */
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.transfer {
  background-color: #e0e0e0;
  color: #333;
}

.conference {
  background-color: #e0e0e0;
  color: #333;
}

.end {
  background-color: #f44336;
  color: white;
}

.end:hover {
  background-color: #d32f2f;
}

.collapse-button {
  background: black;
  border: 8px;
  border-radius: 10px;
  color: #999;
  cursor: pointer;
  font-size: 16px;
  left: 24em;
  /* position: relative; */

}

.info {
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 0px;
  margin-top: 22px;
  padding: 8px;
}

.info-item {
  display: flex;
  justify-content: space-between;
  width: calc(50% - 10px); /* Adjust the width to fit two items per row */
  padding: 8px 0;
}

.label {
  color: #333;
  font-weight: 700;
  width: 28%;
  font-size: 10px;
}

.value {
  color: #666;
    width: 70%;
    font-size: 11px;
}

/* Responsive Design */
@media (max-width: 700px) {
  .customer-details-container {
    padding: 10px;
  }

  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .customer-info {
    margin-left: -1em;
    scale: 0.8;
  }

  .action-buttons {
    flex-wrap: wrap;
    gap: 5px;
    margin-left: 13em;
    margin-top: -1.5em;
    scale:0.8
  }

  .action-buttons .btn {
    padding: 6px 10px;
    font-size: 12px;
    border-radius: 16px;
  }

  .info-item {
    width: 100%;
  }
}
