@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  --font-family: var(--brand-font-regular, "CiscoSansTT Regular"),
    "CiscoSansTT Regular", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

#root {
  height: 100vh;
}
.text-xl {
  line-height: 1.9em;
}
